// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-apple-js": () => import("./../src/pages/apple.js" /* webpackChunkName: "component---src-pages-apple-js" */),
  "component---src-pages-b-1-js": () => import("./../src/pages/b1.js" /* webpackChunkName: "component---src-pages-b-1-js" */),
  "component---src-pages-beats-js": () => import("./../src/pages/beats.js" /* webpackChunkName: "component---src-pages-beats-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-temp-js": () => import("./../src/pages/index-Temp.js" /* webpackChunkName: "component---src-pages-index-temp-js" */),
  "component---src-pages-process-js": () => import("./../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-tokr-js": () => import("./../src/pages/tokr.js" /* webpackChunkName: "component---src-pages-tokr-js" */),
  "component---src-pages-work-js": () => import("./../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

